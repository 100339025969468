import { Survey } from "survey-react-ui";
import { Model } from "survey-core";
import surveyJson from "./survey.json";
import theme from "./survey_theme.json";
import "survey-core/defaultV2.min.css";
import { useEffect, useState } from "react";
import { app } from "./firebase";
import moment from 'moment';
import {
  getFirestore,
  getDocs,
  query,
  collection,
  orderBy,
  limitToLast,addDoc, where, FieldValue, Timestamp
} from "firebase/firestore";
import { BallTriangle } from "react-loader-spinner";
import {Helmet} from "react-helmet";
function App() {
  const [surveyDesign, setSurveyDesign] = useState(surveyJson);
  const [isLoading, setIsLoading] = useState(true);
  const [episodeData, setEpisodeData] = useState({})
  
  async function getEpisodes() {
    const db = getFirestore(app);
    const episodeRef = collection(db, "episodes");
    const date =Timestamp.fromDate(new Date());
    const episodeQuery = query(
      episodeRef,
      where("episode_date", ">", date),
    );
    const docSnapshot = await getDocs(episodeQuery);
    const detail = {};
    const choices = [];
    docSnapshot.docs.forEach((doc) => {
      if (doc.exists()) {
        const data = doc.data();
        choices.push({
          value: doc.id,
          text: `Episode ${data["episode_index"]}: ${data["episode_title"]}`,
        });
        detail[doc.id]=data;
      }
    });
    const newDesign = surveyDesign;
    newDesign["pages"][0]["elements"][0]["choices"] = choices;
    setSurveyDesign(newDesign);
    setEpisodeData(detail)
    setIsLoading(false)
  }

  const survey = new Model(surveyDesign);
  survey.applyTheme(theme);
  survey.getQuestionByName("select_episode").onItemValuePropertyChanged.add(function(sender,options){if (options.newValue) {
    const guestSide =[]
    guestSide.push({
      value: 'group_a',
      text: episodeData[options.obj.id]["group_a"]
    })
    guestSide.push({
      value: 'group_b',
      text: episodeData[options.obj.id]["group_b"]
    })
   const date =  moment.unix(episodeData[options.obj.id]["episode_date"]["seconds"]).format("dddd [-] MMMM Do YYYY")
    survey.getQuestionByName("episode_detail")["html"]=`${episodeData[options.obj.id]["episode_description"]}`
    survey.getQuestionByName("guest_side")["choices"]=guestSide

    survey.getQuestionByName("episode_date")["html"]=`<h5>${date} - ከቀኑ 7 ሰአት በ ${episodeData[options.obj.id]["episode_place"]}</h5>`
    console.log(JSON.stringify(survey.getQuestionByName("episode_detail")))
    // const newDesign = surveyDesign;
    // newDesign["pages"][0]["elements"][1]["html"] = `<h4>Testing</h4>`;
    // setSurveyDesign(newDesign);
  }})
  survey.onComplete.add(function(sender, option){
    const uid = sender.data["select_episode"][0]
     const db = getFirestore(app);
    const episodeRef = collection(db, `episodes/${uid}/guest`);
    addDoc(episodeRef,sender.data)
    console.log(JSON.stringify(sender.data))
  })
  useEffect(() => {
    getEpisodes();
    
  }, []);
  useEffect(() => {
    
  }, [])
  
  return (
    <div style={{ position: 'relative' }}>
      <Helmet>
                <meta charSet="utf-8" />
                <title>ፊት ለፊት ሾ</title>
              
            </Helmet>
    {/* Survey Component */}
    <Survey model={survey} />

    {/* Loading Modal */}
    {isLoading && (
      <div
        style={{
          position: 'fixed', // Fixed position to cover the entire viewport
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(255, 255, 255, 0.9)', // White background with some opacity
          zIndex: 2, // Higher z-index to overlay everything
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          pointerEvents: 'auto', // Allow clicking on the modal
        }}
      >
        <BallTriangle
          height={100}
          width={100}
          radius={5}
          color="#4fa94d"
          ariaLabel="ball-triangle-loading"
          wrapperClass={{}}
          wrapperStyle=""
          visible={true}
        />
      </div>
    )}
  </div>
  );
}

export default App;
// /home/miu4pzh2iiho/public_html/morningsweden.se/wp-content/uploads/wp-file-manager-pro/fm_backup/