// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { initializeAppCheck, getToken, ReCaptchaV3Provider } from 'firebase/app-check';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA-vFhES8hWqe7YMU5jvz1r3e6XxJj45U4",
  authDomain: "fitlefit-application.firebaseapp.com",
  projectId: "fitlefit-application",
  storageBucket: "fitlefit-application.appspot.com",
  messagingSenderId: "668988046027",
  appId: "1:668988046027:web:c196d678a196931aa7b94e",
  measurementId: "G-T9W4H6S4YM"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const appCheck = initializeAppCheck(
  app,
  { provider: new ReCaptchaV3Provider("6LfcEWooAAAAAFCtofvTsISUGHWb_geloR5Q975e") } // ReCaptchaV3Provider or CustomProvider
);
const analytics = getAnalytics(app);
